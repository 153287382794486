

<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/images/logo/Manajero-logo.png" alt="Manajero" style="width: 70%" class="col-sm-12"/>
    </a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="medium">
    <nb-action class="control-item" *ngIf="!noInternetConnection">
      <nb-icon icon="wifi-outline" status="success"
               nbTooltip="Online" nbTooltipStatus="success"></nb-icon>
    </nb-action>
    <nb-action class="control-item" *ngIf="noInternetConnection">
      <nb-icon icon="wifi-off-outline" status="danger"
               nbTooltip="Offline" nbTooltipStatus="danger"></nb-icon>
    </nb-action>
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>


    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="img">

      </nb-user>
    </nb-action>
  </nb-actions>

</div>

<nb-layout>

  <nb-layout-column>

    <div class="background">
      <div class="shape"></div>
      <div class="shape"></div>
    </div>

    <form [formGroup]="loginform" id="div5">
      <tr>
        <img src="assets/images/logo/Manajero-logo.png" alt="" style="width: 40%; margin-left: 120px; " />
      </tr>
      <tr>
        <h4 style="color: #fcfcfc; margin-left: 120px; ">WELCOME</h4>
      </tr>
      <label>Email&#42;</label>
      <input type="email" placeholder="Email" formControlName="email" required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
      <div *ngIf="email.invalid && email.touched">
        <div class="danger-text" *ngIf="email.errors?.required">
          Email is required!
        </div>
        <div *ngIf="email.errors?.email">
          <div class="danger-text">Your email is invalid, please check</div>
        </div>
      </div>
      <div class="input-group">
        <label>Password&#42;</label>
      </div>
      <div class="password-input d-flex align-items-center">
        <br>
        <input type="{{ showPassword ? 'text' : 'password' }}" placeholder="Enter your password" formControlName="password">
        <span>
            <svg *ngIf="!showPassword" (click)="toggleShowPassword()" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="#083570" class="bi bi-eye-fill" viewBox="0 0 16 16" style="cursor: pointer">
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>
            <svg *ngIf="showPassword" (click)="toggleShowPassword()" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="#083570" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style="cursor: pointer">
              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
            </svg>
          </span>
      </div>
      <div *ngIf="password.touched&&password.invalid">
        <div class="danger-text" *ngIf="password.errors?.required">
          Password is required!
        </div>
      </div>

      <div class="remember-me">
        <nb-checkbox>Remember me</nb-checkbox>
      </div>

      <div style="align-self: flex-end; width: 100%; text-align: right;">
        <a href="/auth/forget" style="margin-bottom: 20px; text-decoration: underline; color: #007bff; font-weight: bold;">Forget password?</a>
      </div>

      <nb-actions style="display: flex; justify-content: center; margin-top: 1%;">
        <nb-action  title="Facebook" >
            <img src='../../../../../assets/images/facebook.png' width="25px"/>
        </nb-action>
        <nb-action  title="Facebook">
          <img src='../../../../../assets/images/linkedin.png' width="25px"/>
        </nb-action>
        <nb-action *ngIf="!token" (click)="loginwithGmail()">
        <img src='../../../../../assets/images/gmaaail.png' width="25px"/>
        </nb-action>
      </nb-actions>
      <!-- <button nbButton status="success" *ngIf="!token" (click)="loginwithGmail()">Sign In with Google</button>
          <button nbButton status="warning" *ngIf="token" (click)="logout()">Sign Out</button> -->

      <div class="form-group-cap">
        <re-captcha [siteKey]="recaptchaSecret" (resolved)="onCaptchaResolved($event)">
        </re-captcha>
      </div>

      <div style="display: flex; justify-content: center;">
        <button (click)="login()" nbButton status="primary" [disabled]="loginform.invalid || btnDis || !captchaResponse" hero>
          Sign In
        </button>
      </div>
    </form>


  </nb-layout-column>

</nb-layout>

<nb-layout>
  <nb-layout-column>
    <div class="background">
      <div class="shape"></div>
      <div class="shape"></div>
    </div>
    <form [formGroup]="modelsform" id="div5">
      <div class="search-container">
        <div class="input-container">
          <i class="fa fa-search search-icon"></i>
          <input type="text" formControlName="searchValue" placeholder="" class="search-input">
        </div>
      </div>
      <div *ngFor="let app of filteredApps">
      </div>
      <div class="apps-scroll-container">
        <div class="app-grid">
          <!-- Utilisation de ng-container pour appliquer *ngIf -->
          <ng-container *ngFor="let app of displayedApps">
            <div class="app-item" *ngIf="canDisplayApp(app.name)" (click)="navigate(app.name)">
              <div class="app-tile">
                <img [src]="app.icon" alt="{{ app.name }}" class="app-icon-image">
              </div>
              <div class="app-label">{{ app.name }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </nb-layout-column>
</nb-layout>


